<template>
  <div>
    <!-- Desktop -->
    <div class="pending-form__desktop">
      <p class="mt-3 text-left pending-forms__title">Documentos</p>
      <v-col cols="12" class="pt-0">
        <v-row class="pending-forms__container-pending">
          <template>
            <v-row >
              <v-col
                cols="3"
                v-for="data in listDocuments"
                :key="data.id"
                class="pt-1"
              >
                <v-card
                  width="270"
                  class="mx-2 mt-0 mb-0 pending-forms__hover-card"
                  :input-value="active"
                  style="border-radius: 15px !important"
                  depressed
                  rounded
                  elevation="0"
                  outlined
                >
                  <v-card-title>
                    <v-layout justify-center>
                      <img
                        width="100"
                        :src="origin + '/icon/formulariosv2.svg'"
                      />
                    </v-layout>
                  </v-card-title>
                  <v-card-text
                    style="height: 60px"
                    class="d-flex justify-center"
                  >
                    <p
                      class="d-flex justify-center pending-forms__card-title"
                      style="font-size: 19px"
                    >
                      {{ data.nombre }}
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-0 mt-3 mb-3">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        @click="ieDetails(data)"
                        block
                        rounded
                        style="color: #fff"
                        :style="{
                          'background-color': hover ? '#324CA1' : '#466BE3',
                        }"
                      >
                        Ver
                      </v-btn>
                    </v-hover>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- <v-row v-else>
              <v-col>
                <p>No hay documentos disponibles</p>
              </v-col>
            </v-row> -->
          </template>
        </v-row>
      </v-col>
    </div>

    <!-- inicio tablet -->
    <div class="mb-t-pending">
      <v-row class="mb-8">
        <v-col class="mb-0 pb-0 mt-0 mb-0 pl-2">
          <p class="text-title-sm pt-3 pb-3 pl-0 mb-0">Documentos</p>
        </v-col>
        <v-slide-group
          multiple
          show-arrows
          prev-icon="mdi-chevron-left-circle-outline"
          next-icon="mdi-chevron-right-circle-outline"
        >
          <v-slide-item
            style="min-width: 10px"
            v-for="data in listDocuments"
            :key="data.id"
            v-if="listDocuments.length != 0"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="mx-2 border-control-card-p mt-0"
              :input-value="active"
              active-class="purple white--text"
              style="border-radius: 8px; border: 1px solid #466be3"
              depressed
              rounded
              @click="toggle"
              elevation="0"
              width="250"
            >
              <v-row class="mx-0 px-0">
                <v-col cols="8" class="mx-0 px-0">
                  <p
                    @click="ieDetails(data)"
                    class="text-p-sm-blue text-1-lines mb-0 mt-3"
                  >
                    {{ data.title }}
                  </p>
                </v-col>
                <v-col cols="4" class="mx-0 px-0">
                  <img
                    :src="origin + '/icon/formularios-1.svg'"
                    alt="formularios"
                    class="pt-2"
                    style="width: 50px"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-row>
    </div>

    <!-- fin tablet-->

    <!-- inicio mobile -->
    <div class="mb-sm-pending-form">
      <v-row class="py-0">
        <v-col class="mb-0 pb-0 mt-0 pt-0 mb-0 pl-2">
          <p class="text-title-sm pt-0 pb-3 pl-0 mb-0">Documentos</p>
        </v-col>
        <v-col cols="12" class="mb-10 pb-0 mt-0 mb-0 pl-0 pr-0">
          <v-slide-group
            multiple
            show-arrows
            prev-icon="mdi-chevron-left-circle-outline"
            next-icon="mdi-chevron-right-circle-outline"
          >
            <v-slide-item
              style="min-width: 10px; width: 100%"
              v-for="data in listDocuments"
              v-if="listDocuments.length != 0"
              :key="data.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="mx-2 border-control-card-p mt-0"
                :input-value="active"
                active-class="purple white--text"
                style="border-radius: 8px; border: 1px solid #466be3"
                depressed
                rounded
                @click="toggle"
                elevation="0"
                width="250"
              >
                <v-row class="mx-0 px-0">
                  <v-col cols="8" class="mx-0 px-0">
                    <p
                      @click="ieDetails(data)"
                      class="text-p-sm-blue text-1-lines mb-0 mt-3"
                    >
                      {{ data.title }}
                    </p>
                  </v-col>
                  <v-col cols="4" class="mx-0 px-0">
                    <img
                      :src="origin + '/icon/formularios-1.svg'"
                      alt="formularios"
                      class="pt-2"
                      style="width: 50px"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </div>

    <!-- fin mobile-->

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import vueShowMoreText from "vue-show-more-text";
import Api from "@/utils/api";

export default {
  components: {
    vueShowMoreText,
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      name: "",
      origin: window.location.origin,
      listDocuments: "",
      loading: {
        all: false,
      },
      currentUser: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    ieDetails(data) {
      localStorage.setItem("URL", data.url);
      this.$router.push({ name: "tycDetail" });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getDocuments() {
      var dataCity = {
        pais: this.currentUser.country,
        ciudad: this.currentUser.headquarters_city,
        sede: this.currentUser.headquarters,
      };
      Api.TYC()
        .listTyC(dataCity)
        .then((resp) => resp.json())
        .then((data) => {
          this.listDocuments = data.data;

          // if(data.data.status === 200){
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser) {
      this.getDocuments();
    }
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.getDocuments();
      }
    },
  },
  created() {
    this.getDocuments();
    this.getToken();
  },
};
</script>
<style lang="less">
@import "../../assets/css/main";

// .navidad-style{
//   // background-color: red;
//   background-image: url(../../assets/img/navidad/border-navidad2.png);
//   background-repeat: round;
//   background-position-y: 2px;
// }
.pending-form__desktop {
  display: block;
}

.mdi-chevron-right-circle::before {
  content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle::before {
  content: "\F0B28";
  color: #466be3;
}

.border-control-card {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0 !important;
  /* Primarios/Azul claro */
  background: #f2f4ff !important;
  /* Primarios/azul */
  border: 1px solid #466be3 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
}

.border-control-card-p {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0 !important;
  /* Primarios/azul */
  border: 1px solid #466be3 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  flex: 0 1 24px !important;
  min-width: 32px !important;
}

.btn-color-out {
  background: #fff !important;
}

.mb-xl-pending {
  color: #466be3;
  display: flex;
}

.mb-sm-pending-form {
  display: none;
}

.mb-t-pending {
  display: none;
}

.forms__alert-pending {
  width: 100%;
}

.pending-forms__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}

.pending-forms__container-pending {
  margin-left: -30px;
  height: 600px;
  // overflow-y: scroll;
}

.pending-forms__hover-card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.pending-forms__card-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: bold;
  margin-bottom: 0px !important;
  line-height: 28px;
  font-size: 18px;
  height: 60px;
  color: #353535;
  overflow: hidden;
}

/* LAPTOP 4k */
@media (max-width: 1900px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
}

/* TABLET */
@media (max-width: 1023px) {
  .pending-form__desktop {
    display: none;
  }

  .mb-xl-pending {
    color: #466be3;
    display: none !important;
  }

  .mb-t-pending {
    display: block;
  }

  .mb-sm-pending-form {
    display: none;
    //padding-bottom: 50px;
  }

  .text-title {
    color: #466be3 !important;
    font-size: 19px !important;
  }
  .text-p-sm-blue {
    font-family: "RobotoRegular";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #466be3;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-title-sm {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }
}

/* MOBILE L - 767px*/
@media (max-width: 767px) {
  .mb-xl-pending {
    color: #466be3;
    display: none !important;
  }

  .mb-t-pending {
    display: none !important;
  }

  .mb-sm-pending-form {
    display: block;
    //padding-bottom: 50px;
  }

  .text-p-sm-blue {
    font-family: "RobotoRegular";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #466be3;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-title-sm {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }

  .forms__alert-pending {
    width: 95%;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .text-p-sm-blue {
    font-family: "RobotoRegular";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #466be3;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-title-sm {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .text-p-sm-blue {
    font-family: "RobotoRegular";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #466be3;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-title-sm {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }
}
</style>
